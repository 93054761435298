import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="gradient-background">
        <div className="content-center">
          <h1>Clausula Abastada</h1>
          <p style={{ color: "#94a3b8" }}>
            Construção e Obras Públicas, Lda. <br />
            <a
              style={{ color: "#ddd" }}
              href="mailto:contato@clausulaabastada.pt"
            >
              contato@clausulaabastada.pt
            </a>
          </p>
        </div>
        <footer className="footer-content">
          Desenvolvimento de websites <br />
          <a style={{ color: "#ddd" }} href="https://scriptembal.pt">
            www.scriptembal.pt
          </a>
        </footer>
      </div>
    </div>
  );
}

export default App;
